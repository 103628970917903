import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
import store from '@/store';

Vue.use(Router);

export default new Router({
  saveScrollPosition: true,
  mode: 'history',
  routes: [
    // No authentication required
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
      meta: {
        requiresAuth: false,
      },
      beforeEnter: (to, from, next) => {
        // Check if redirect is present
        if (to.query.redirect && from.name !== 'checkout') {
          let redirectUrl;
          redirectUrl = to.query.redirect;
          const resourceRegExp = new RegExp(
            /^\/resources\/[a-zA-Z0-9]{10}\/(([a-zA-Z0-9]{6}$)|([a-zA-Z0-9]{6}\/(de|fr|it)$))/,
            'g',
          );
          const results = redirectUrl.match(resourceRegExp) || [];
          const isResourceURL = results.length > 0;
          if (isResourceURL) {
            const monitorRegExp = new RegExp(/\/[a-zA-Z0-9]{10}\//, 'g');
            const articleRegExp = new RegExp(/\/[a-zA-Z0-9]{6}($|\/)/, 'g');
            const languageRegExp = new RegExp(/\/(de|fr|it)$/, 'g');
            const monitorMatch = redirectUrl.match(monitorRegExp);
            const articleMatch = redirectUrl.match(articleRegExp);
            const languageMatch = redirectUrl.match(languageRegExp);
            const monitorId =
              monitorMatch.length > 0
                ? monitorMatch[0].substring(1, monitorMatch[0].length - 1)
                : undefined;
            let articleId = articleMatch.length > 0 ? articleMatch[0].substring(1) : undefined;
            if (articleId.length > 6) articleId = articleId.substring(0, articleId.length - 1);
            const language =
              languageMatch && languageMatch.length > 0 ? languageMatch[0].substring(1) : undefined;

            if (language) {
              store.dispatch('changeLanguage', language);
            }

            next({
              path: `/checkout/${monitorId}/${articleId}`,
            });
          } else {
            next();
          }
        } else {
          next();
        }
      },
    },
    {
      path: '/register/:token',
      name: 'register',
      component: () => import('./views/LoginRegister.vue'),
      meta: {
        requiresAuth: false,
      },
      beforeEnter: (to, from, next) => {
        axios
          .post('/auth/register', { token: to.params.token })
          .then(() => {
            next();
          })
          .catch(() => {
            // next({ name: 'login' });
            next({ params: { token: to.params.token } });
          });
      },
    },
    {
      path: '/reset/:token?',
      name: 'reset',
      component: () => import('./views/Reset.vue'),
      meta: {
        requiresAuth: false,
      },
      props: true,
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('./views/SignUp.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/checkout/:monitor/:article',
      name: 'checkout',
      component: () => import('./views/CheckOut.vue'),
      meta: {
        requiresAuth: false,
      },
      props: true,
      beforeEnter: (to, from, next) => {
        // Check if params is present and valid
        const monitor = to.params.monitor;
        const article = to.params.article;

        if (monitor && article) {
          const monitorRegExp = new RegExp(/[a-zA-Z0-9]{10}$/, 'g');
          const articleRegExp = new RegExp(/[a-zA-Z0-9]{6}$/, 'g');
          const monitorResult = monitor.match(monitorRegExp);
          const articleResult = article.match(articleRegExp);

          if (
            monitorResult !== null &&
            monitorResult.length > 0 &&
            articleResult !== null &&
            articleResult.length > 0
          ) {
            next();
          } else {
            next('/404');
          }
        } else {
          next('/404');
        }
      },
    },
    {
      path: '/spc/:token/:status/:type?',
      name: 'saferPayCallback',
      component: () => import('./views/SaferPayCallback.vue'),
      meta: {
        requiresAuth: false,
      },
      props: true,
    },
    // Authentication required
    {
      path: '/',
      name: 'home',
      component: () => import('./views/HomeChips.vue'),
      meta: {
        requiresAuth: true,
        resource: 'home',
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('./views/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        resource: 'dashboard',
      },
    },
    {
      path: '/calendar/:appointmentId?',
      name: 'calendar',
      component: () => import('./views/Calendar.vue'),
      meta: {
        requiresAuth: true,
        resource: 'calendar',
      },
      props: true,
    },
    {
      path: '/subscriptions',
      name: 'subscriptions',
      component: () => import('./views/Subscriptions.vue'),
      meta: {
        requiresAuth: true,
        resource: 'subscriptions',
      },
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('./views/Account.vue'),
      meta: {
        requiresAuth: true,
        resource: 'account',
      },
    },
    {
      path: '/account/me',
      name: 'me',
      component: () => import('./views/AccountPersonal.vue'),
      meta: {
        requiresAuth: true,
        resource: 'account',
      },
    },
    {
      path: '/account/security',
      name: 'security',
      component: () => import('./views/AccountSecurity.vue'),
      meta: {
        requiresAuth: true,
        resource: 'account',
      },
    },
    {
      path: '/account/subscriptions',
      name: 'subscription',
      component: () => import('./views/AccountSubscriptions.vue'),
      meta: {
        requiresAuth: true,
        resource: 'account',
      },
    },
    {
      path: '/account/ccsetup',
      name: 'ccsetup',
      component: () => import('./views/AccountCcsetup.vue'),
      meta: {
        requiresAuth: true,
        resource: 'account',
      },
    },
    {
      path: '/account/cc/:status/:token',
      name: 'ccsetup2',
      component: () => import('./views/AccountCcsetup.vue'),
      meta: {
        requiresAuth: true,
        resource: 'account',
      },
      props: true,
    },
    {
      path: '/account/routes',
      name: 'routes',
      component: () => import('./views/AccountRoutes.vue'),
      meta: {
        requiresAuth: true,
        requiresLicense: true,
        license: 'admin',
        resource: 'routes',
      },
    },
    {
      path: '/account/mymonitor',
      name: 'mymonitor',
      component: () => import('./views/AccountMyMonitor.vue'),
      meta: {
        requiresAuth: true,
        resource: 'account',
      },
    },
    {
      path: '/driving-school',
      alias: '/account/driving-school',
      name: 'drivingSchool',
      component: () => import('./views/DrivingSchool.vue'),
      meta: {
        requiresAuth: true,
        requiresLicense: true,
        license: 'admin',
        resource: 'drivingSchool',
      },
    },
    {
      path: '/shop-settings',
      alias: '/account/shop-settings',
      name: 'shopSettings',
      component: () => import('./views/ShopSettings.vue'),
      meta: {
        requiresAuth: true,
        resource: 'shop',
      },
    },
    {
      path: '/infrastructures',
      alias: '/account/infrastructures',
      name: 'infrastructures',
      component: () => import('./views/InfraSettings.vue'),
      meta: {
        requiresAuth: true,
        requiresLicense: true,
        license: 'admin',
        resource: 'infrastructures',
      },
    },
    {
      path: '/students',
      name: 'students',
      component: () => import('./views/Students.vue'),
      meta: {
        requiresAuth: true,
        resource: 'students',
      },
    },
    {
      path: '/students/profile',
      name: 'student',
      component: () => import('./views/Student.vue'),
      meta: {
        requiresAuth: true,
        resource: 'students',
      },
    },
    {
      path: '/students/requests',
      name: 'requests',
      component: () => import('./views/StudentsRequests.vue'),
      meta: {
        requiresAuth: true,
        resource: 'students',
      },
    },
    {
      path: '/comm-settings',
      alias: '/account/comm-settings',
      name: 'communicationSettings',
      component: () => import('./views/CommunicationSettings.vue'),
      meta: {
        requiresAuth: true,
        requiresLicense: true,
        license: 'admin',
        resource: 'communication',
      },
    },
    {
      path: '/comm-logs',
      alias: '/account/comm-logs',
      name: 'communicationLogs',
      component: () => import('./views/CommunicationLogs.vue'),
      meta: {
        requiresAuth: true,
        requiresLicense: true,
        license: 'admin',
        resource: 'communication',
      },
    },
    {
      path: '/account/orders-list',
      name: 'ordersList',
      component: () => import('./views/Orders.vue'),
      meta: {
        requiresAuth: true,
        resource: 'account',
      },
    },
    {
      path: '/courses',
      alias: '/account/courses',
      name: 'courses',
      component: () => import('./views/Courses.vue'),
      meta: {
        requiresAuth: true,
        requiresLicense: true,
        license: 'admin',
        resource: 'courses',
      },
    },
    {
      path: '/account/vouchers',
      name: 'vouchers',
      component: () => import('./views/Vouchers.vue'),
      meta: {
        requiresAuth: true,
        requiresLicense: true,
        license: 'admin',
        resource: 'account',
      },
    },
    {
      path: '/shop',
      name: 'shop',
      component: () => import('./views/Shop.vue'),
      meta: {
        requiresAuth: true,
        resource: 'shop',
      },
    },
    {
      path: '/trainingcards-settings',
      name: 'trainingcards-settings',
      alias: '/account/trainingcards-settings',
      component: () => import('./views/TrainingcardsSettings.vue'),
      meta: {
        requiresAuth: true,
        requiresLicense: true,
        license: 'admin',
        resource: 'trainingcardsSettings',
      },
    },
    {
      path: '/resources/(:monitor/:article/:language?)?',
      name: 'resources',
      component: () => import('./views/Resources.vue'),
      meta: {
        requiresAuth: true,
        resource: 'resources',
      },
    },
    {
      path: '/resources/:monitor/:article/:language?',
      name: 'extResources',
      component: () => import('./views/Resources.vue'),
      meta: {
        requiresAuth: true,
        resource: 'resources',
      },
      props: true,
    },
    {
      path: '/learning/resources',
      name: 'learningResources',
      component: () => import('./views/LearningResources.vue'),
      meta: {
        requiresAuth: true,
        resource: 'resources',
      },
    },
    {
      path: '/training-card',
      name: 'trainingCard',
      component: () => import('./views/TrainingCard.vue'),
      meta: {
        requiresAuth: true,
        resource: 'students',
      },
      children: [
        {
          path: 'choice',
          name: 'trainingCardChoice',
          component: () => import('./components/TrainingCardChoice.vue'),
          meta: {
            requiresAuth: true,
            resource: 'students',
          },
          props: true,
        },
        {
          path: 'view',
          name: 'trainingCardView',
          component: () => import('./components/TrainingCardView.vue'),
          meta: {
            requiresAuth: true,
            resource: 'students',
          },
          props: true,
        },
      ],
    },
    {
      path: '/lesson',
      name: 'lesson',
      component: () => import('./views/Lesson.vue'),
      meta: {
        requiresAuth: true,
        resource: 'lesson',
      },
      children: [
        {
          path: 'start',
          name: 'lessonStart',
          component: () => import('./components/LessonStartComponent.vue'),
          meta: {
            requiresAuth: true,
            resource: 'lesson',
          },
          props: true,
        },
        {
          path: 'on',
          name: 'lessonOn',
          component: () => import('./components/LessonOnComponent.vue'),
          meta: {
            requiresAuth: true,
            resource: 'lesson',
          },
          props: true,
        },
        {
          path: 'end',
          name: 'lessonEnd',
          component: () => import('./components/LessonEndComponent.vue'),
          meta: {
            requiresAuth: true,
            resource: 'lesson',
          },
          props: true,
        },
      ],
    },
    {
      path: '/theory-test',
      name: 'theoryTest',
      component: () => import('./views/TheoryTest.vue'),
      meta: {
        requiresAuth: true,
        resource: 'theoryTest',
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('./views/Settings.vue'),
      meta: {
        requiresAuth: true,
        resource: 'settings',
      },
      children: [
        {
          path: 'orders',
          component: () => import('./views/Orders.vue'),
          meta: {
            requiresAuth: true,
            resource: 'settings',
          },
        },
        {
          path: 'vouchers',
          component: () => import('./views/Vouchers.vue'),
          meta: {
            requiresAuth: true,
            resource: 'settings',
          },
        },
      ],
    },
    {
      path: '/manageClients',
      name: 'manageClients',
      component: () => import('./views/ManageClients.vue'),
      meta: {
        requiresAuth: true,
        resource: 'manageClients',
      },
    },
    {
      path: '/bf-students',
      name: 'bgStudents',
      component: () => import('./views/BFStudents.vue'),
      meta: {
        requiresAuth: true,
        resource: 'bfStudents',
      },
    },
    {
      path: '/403',
      name: '403',
      component: () => import('./views/NotLicensed.vue'),
      meta: {
        requiresAuth: true,
        resource: 'home',
      },
    },
    {
      path: '/gc',
      name: 'googleCallback',
      component: () => import('./views/GoogleCallback.vue'),
      meta: {
        requiresAuth: true,
        resource: 'communication',
      },
      props: true,
    },
    {
      path: '/accounting/billing',
      name: 'billing',
      component: () => import('./views/BillingView.vue'),
      meta: {
        requiresAuth: true,
        requiresLicense: true,
        license: 'admin',
        resource: 'accounting',
      },
    },
    {
      path: '/privacyPolicy',
      name: 'privacyPolicy',
      component: () => import('./views/PrivacyPolicy.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    // Path didn't match - no authentication required
    {
      path: '*',
      name: '404',
      component: () => import('./views/NotFound.vue'),
      meta: {
        requiresAuth: false,
      },
    },
  ],
});
