import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import BootstrapVue from 'bootstrap-vue';
import VeeValidate from 'vee-validate';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter, faTimesCircle, faBookOpen } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';
import enValidationMessages from 'vee-validate/dist/locale/en';
import frValidationMessages from 'vee-validate/dist/locale/fr';
import deValidationMessages from 'vee-validate/dist/locale/de';
import itValidationMessages from 'vee-validate/dist/locale/it';
import '@progress/kendo-theme-bootstrap/dist/all.scss';
import '@progress/kendo-ui';
import './kendo-ui-license.js';
import '@progress/kendo-ui/js/cultures/kendo.culture.fr-CH';
import '@progress/kendo-ui/js/cultures/kendo.culture.de-CH';
import '@progress/kendo-ui/js/cultures/kendo.culture.it-CH';
import '@progress/kendo-ui/js/cultures/kendo.culture.en-GB';
/*
import '@progress/kendo-ui/js/messages/kendo.messages.fr-CH';
import '@progress/kendo-ui/js/messages/kendo.messages.de-CH';
import '@progress/kendo-ui/js/messages/kendo.messages.it-CH';
import '@progress/kendo-ui/js/messages/kendo.messages.en-GB';
*/
import {
  Scheduler,
  SchedulerResource,
  SchedulerView,
  SchedulerInstaller,
} from '@progress/kendo-scheduler-vue-wrapper';
import { Window, WindowInstaller } from '@progress/kendo-window-vue-wrapper';
import {
  Button,
  ButtonGroup,
  ButtonGroupButton,
  ButtonsInstaller,
} from '@progress/kendo-buttons-vue-wrapper';
import { Validator, ValidatorInstaller } from '@progress/kendo-validator-vue-wrapper';
import { MaskedTextBox, ColorPicker, InputsInstaller } from '@progress/kendo-inputs-vue-wrapper';
import {
  DropDownList,
  ComboBox,
  MultiSelect,
  DropdownsInstaller,
} from '@progress/kendo-dropdowns-vue-wrapper';
import { KendoTooltip, KendoPopupsInstaller } from '@progress/kendo-popups-vue-wrapper';
import {
  DataSource,
  SchedulerDataSource,
  DataSourceInstaller,
} from '@progress/kendo-datasource-vue-wrapper';
import {
  DatePicker,
  DateTimePicker,
  DateinputsInstaller,
} from '@progress/kendo-dateinputs-vue-wrapper';
import { Chart, ChartSeriesItem, ChartInstaller } from '@progress/kendo-charts-vue-wrapper';
import { ListBox, ListBoxInstaller } from '@progress/kendo-listbox-vue-wrapper';
import { MediaPlayer, MediaPlayerInstaller } from '@progress/kendo-mediaplayer-vue-wrapper';
import { Cropper } from 'vue-advanced-cropper';
import md5 from 'crypto-js/md5';
import sha256 from 'crypto-js/sha256';
import qs from 'qs';
import moment from 'moment'; // http://momentjs.com/
import lodash from 'lodash';
import App from './App.vue';
import store from './store';
import router from './router';
import VueNBP from './plugins/vue-nbp';
import i18n from './plugins/i18n';
import VueAcl from './plugins/vue-acl';
import languages from './locales/languages';
import './assets/sass/app.scss';

// Configure Vue Plugins
axios.defaults.baseURL = process.env.VUE_APP_API;
axios.interceptors.request.use(
  (config) =>
    new Promise((resolve) => {
      const localConfig = config;
      const jwt = sessionStorage.getItem('jwt');
      if (jwt) {
        localConfig.headers.Authorization = `Bearer ${jwt}`;
      }
      resolve(localConfig);
    }),
);

axios.interceptors.response.use(
  (response) =>
    new Promise((resolve) => {
      if (response.data.jwt) {
        const { jwt } = response.data;

        if (jwt) {
          sessionStorage.setItem('jwt', jwt);
        }
      }

      resolve(response);
    }),
  (error) =>
    new Promise((resolve, reject) => {
      const statusCode = error.response ? error.response.status : 500;

      switch (statusCode) {
        case 401:
          if (router.currentRoute.name !== 'login') router.push({ path: '/login' });
          break;
        default:
          if (error.response) {
            if (error.response.data.jwt) {
              sessionStorage.setItem('jwt', error.response.data.jwt);
            }
          }
          break;
      }

      return reject(error);
    }),
);

library.add(faFilter, faTimesCircle, faBookOpen);

Vue.prototype.$getImgUrl = (img) => {
  const images = require.context('@/assets/', false, /\.svg$/);
  return img ? images(`./${img}`) : images('./default.svg');
};

// Register Vue Plugins
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  i18nRootKey: 'validation',
  i18n,
  dictionary: {
    en: enValidationMessages,
    fr: frValidationMessages,
    de: deValidationMessages,
    it: itValidationMessages,
  },
});
Vue.use(SchedulerInstaller);
Vue.use(ButtonsInstaller);
Vue.use(WindowInstaller);
Vue.use(ValidatorInstaller);
Vue.use(InputsInstaller);
Vue.use(KendoPopupsInstaller);
Vue.use(DropdownsInstaller);
Vue.use(DataSourceInstaller);
Vue.use(DateinputsInstaller);
Vue.use(ChartInstaller);
Vue.use(MediaPlayerInstaller);
Vue.use(ListBoxInstaller);

Vue.use(VueNBP, {
  api: `${axios.defaults.baseURL}/auth/commonpassword/`,
  file: '1000000',
  minLength: 10,
  colors: ['danger', 'warning', 'info', 'success'],
});

Vue.use(VueAcl, {
  inheritance: false,
  acl: {
    roles: [
      { name: 'guest', parent: null, aliases: [] },
      { name: 'user', parent: 'guest', aliases: [] },
      {
        name: 'monitor',
        parent: 'user',
        aliases: [
          'moniteur non membre',
          'moniteur membre fre',
          'membre FRE auto-ecole',
          'non membre auto-ecole',
        ],
      },
      { name: 'fre', parent: 'monitor', aliases: [] },
      { name: 'admin', parent: 'fre', aliases: [] },
    ],
    resources: [
      { name: 'app' },
      { name: 'home' },
      { name: 'dashboard' },
      { name: 'calendar' },
      { name: 'students' },
      { name: 'resources' },
      { name: 'subscriptions' },
      { name: 'settings' },
      { name: 'account' },
      { name: 'communication' },
      { name: 'courses' },
      { name: 'infrastructures' },
      { name: 'sari' },
      { name: 'drivingSchool' },
      { name: 'trainingcardsSettings' },
      { name: 'shop' },
      { name: 'lesson' },
      { name: 'theoryTest' },
      { name: 'manageClients' },
      { name: 'routes' },
      { name: 'bfStudents' },
      { name: 'accounting' },
      { name: 'wizard' },
    ],
    rules: [
      {
        role: ['user'],
        resource: ['home', 'dashboard', 'calendar', 'resources', 'account', 'theoryTest', 'shop'],
        privileges: ['read'],
      },
      {
        role: ['monitor'],
        resource: ['drivingSchool', 'subscriptions', 'trainingcardsSettings', 'wizard'],
        privileges: ['read', 'manage'],
      },
      {
        role: ['monitor'],
        resource: [
          'home',
          'students',
          'courses',
          'infrastructures',
          'sari',
          'communication',
          'shop',
          'lesson',
          'routes',
          'accounting',
        ],
        privileges: ['all'],
      },
      {
        role: ['admin', 'fre'],
        resource: ['app'],
        privileges: ['all'],
      },
    ],
  },
});

// Register Vue Components
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('FontAwesomeLayers', FontAwesomeLayers);
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText);

// Register JS Libraries
Object.defineProperty(Vue.prototype, '$md5', { value: md5 });
Object.defineProperty(Vue.prototype, '$sha256', { value: sha256 });
Object.defineProperty(Vue.prototype, '$moment', { value: moment });
Object.defineProperty(Vue.prototype, '$lodash', { value: lodash });
Object.defineProperty(Vue.prototype, '$eventHub', { value: new Vue() });

const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : store.state.lang;

if (lang) {
  const language = languages.find((l) => l.value === lang);
  i18n.locale = language.value;
  moment.locale(language.locale);
  // eslint-disable-next-line no-undef
  import(`@progress/kendo-ui/js/messages/kendo.messages.${language.locale}`);
  // eslint-disable-next-line no-undef
  kendo.culture(language.locale);
  store.dispatch('changeLanguage', language.value);
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Route requires authentication
    if (sessionStorage.getItem('jwt')) {
      // Check authentication validity
      if (!store.getters.me) {
        const user = qs.parse(localStorage.getItem('user'), {
          decoder(str, decoder, charset) {
            const strWithoutPlus = str.replace(/\+/g, ' ');
            if (charset === 'iso-8859-1') {
              // unescape never throws, no try...catch needed:
              return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
            }

            if (/^(\d+|\d*\.\d+)$/.test(str)) {
              return parseFloat(str);
            }

            const keywords = {
              true: true,
              false: false,
              null: null,
              undefined,
            };

            if (str in keywords) {
              return keywords[str];
            }

            // utf-8
            try {
              return decodeURIComponent(strWithoutPlus);
            } catch (e) {
              return strWithoutPlus;
            }
          },
        });
        store.dispatch('authenticate', {
          user,
        });
      }

      const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'));
      store.dispatch('setIsLoggedIn', isLoggedIn);

      if (router.app.$can(to.meta.resource, 'read')) {
        // Get ongoing lesson if exists
        if (localStorage.getItem(`lesson_${store.getters.me.username}`)) {
          const lesson = JSON.parse(localStorage.getItem(`lesson_${store.getters.me.username}`));
          if (lesson.start && lesson.end) {
            lesson.start = new Date(lesson.start.replace(/ /g, 'T'));
            lesson.end = new Date(lesson.end.replace(/ /g, 'T'));
            store.dispatch('setLesson', lesson);
          } else {
            // there is inconsistency in the lesson, we drop it
            store.dispatch('unsetLesson');
          }
        }

        // Get stored jobs
        if (localStorage.getItem('jobs') && store.getters.jobs.length < 1) {
          const jobs = qs.parse(localStorage.getItem('jobs'));
          store.dispatch('setJobs', Object.values(jobs));
        }

        // Get stored chips
        if (localStorage.getItem('chips') && store.getters.chips.length < 1) {
          const chips = qs.parse(localStorage.getItem('chips'));
          store.dispatch('setChips', Object.values(chips));
        }

        // Get stored releases
        if (localStorage.getItem('releases') && store.getters.releases.length < 1) {
          const releases = qs.parse(localStorage.getItem('releases'));
          store.dispatch('setReleases', Object.values(releases));
        }

        // Get stored student
        if (localStorage.getItem('student') && !store.getters.student) {
          const student = qs.parse(localStorage.getItem('student'), {
            decoder(str, decoder, charset) {
              const strWithoutPlus = str.replace(/\+/g, ' ');
              if (charset === 'iso-8859-1') {
                // unescape never throws, no try...catch needed:
                return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
              }

              if (/^(\d+|\d*\.\d+)$/.test(str)) {
                return parseFloat(str);
              }

              const keywords = {
                true: true,
                false: false,
                null: null,
                undefined,
              };

              if (str in keywords) {
                return keywords[str];
              }

              // utf-8
              try {
                return decodeURIComponent(strWithoutPlus);
              } catch (e) {
                return strWithoutPlus;
              }
            },
          });
          store.dispatch('setCurrentStudent', student);
        }

        // Get stored tc
        if (localStorage.getItem('tc') && !store.getters.tc) {
          const tc = qs.parse(localStorage.getItem('tc'), {
            decoder(str, decoder, charset) {
              const strWithoutPlus = str.replace(/\+/g, ' ');
              if (charset === 'iso-8859-1') {
                // unescape never throws, no try...catch needed:
                return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
              }

              if (/^(\d+|\d*\.\d+)$/.test(str)) {
                return parseFloat(str);
              }

              const keywords = {
                true: true,
                false: false,
                null: null,
                undefined,
              };

              if (str in keywords) {
                return keywords[str];
              }

              // utf-8
              try {
                return decodeURIComponent(strWithoutPlus);
              } catch (e) {
                return strWithoutPlus;
              }
            },
          });
          store.dispatch('setCurrentTC', tc);
        }

        // get stored calendar view mode
        const view = localStorage.getItem('view');
        store.dispatch('setView', view);

        // get stored wizard mode
        const wizard = JSON.parse(localStorage.getItem('wizard'));
        store.dispatch('setWizard', wizard);

        // Check if redirect is present
        let redirectUrl;
        if (to.query.redirect) {
          redirectUrl = to.query.redirect;
        }

        // Check licensing
        if (to.matched.some((record) => record.meta.requiresLicense)) {
          let isLicensed = false;
          if (to.meta.license === 'admin') {
            isLicensed = store.getters.hasAdminLicense;
          }

          if (isLicensed || router.app.$hasRole('fre')) {
            if (redirectUrl) {
              router.push({ path: redirectUrl });
            } else {
              next();
            }
          } else {
            router.push({ path: '/403' });
          }
        } else if (to.name !== 'me' && redirectUrl) {
          router.push({ path: redirectUrl });
        } else {
          next();
        }
      } else {
        router.push({ path: '/404' });
      }
    } else if (localStorage.getItem('alt') && localStorage.getItem('ali')) {
      // Automatic login
      const user = qs.parse(localStorage.getItem('user'), {
        decoder(str, decoder, charset) {
          const strWithoutPlus = str.replace(/\+/g, ' ');
          if (charset === 'iso-8859-1') {
            // unescape never throws, no try...catch needed:
            return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
          }

          if (/^(\d+|\d*\.\d+)$/.test(str)) {
            return parseFloat(str);
          }

          const keywords = {
            true: true,
            false: false,
            null: null,
            undefined,
          };

          if (str in keywords) {
            return keywords[str];
          }

          // utf-8
          try {
            return decodeURIComponent(strWithoutPlus);
          } catch (e) {
            return strWithoutPlus;
          }
        },
      });
      axios({
        method: 'POST',
        url: '/auth/prelogin',
        data: { username: user.username },
      })
        .then(async (response) => {
          const alt = localStorage.getItem('alt');
          const ali = localStorage.getItem('ali');
          const token = response.data.data
            ? sha256(response.data.data.token1 + alt).toString()
            : '';
          const reqData = {};
          reqData.token = token;
          reqData.username = user.username;
          reqData.autologinId = ali;
          await axios({
            method: 'POST',
            url: '/auth/autologin',
            data: reqData,
          })
            .then((response2) => {
              const rspData = response2.data;
              if (rspData.data.autologinToken) {
                localStorage.setItem('alt', rspData.data.autologinToken);
              }
              // Login successful
              store.dispatch('authenticate', {
                user: {
                  ...user,
                  ...{
                    licenceDidactique: rspData.data.licence_didactique,
                    licenceFinance: rspData.data.licence_finance,
                    licenceStatistiques: rspData.data.licence_statistiques,
                    licenceAdmin: rspData.data.licence_administrative,
                  },
                },
              });

              // Get stored jobs
              if (localStorage.getItem('jobs') && store.getters.jobs.length < 1) {
                const jobs = qs.parse(localStorage.getItem('jobs'));
                store.dispatch('setJobs', Object.values(jobs));
              }

              // Get stored chips
              if (localStorage.getItem('chips') && store.getters.chips.length < 1) {
                const chips = qs.parse(localStorage.getItem('chips'));
                store.dispatch('setChips', Object.values(chips));
              }

              // Get stored releases
              if (localStorage.getItem('releases') && store.getters.releases.length < 1) {
                const releases = qs.parse(localStorage.getItem('releases'));
                store.dispatch('setReleases', Object.values(releases));
              }

              // Get stored student
              if (localStorage.getItem('student') && !store.getters.student) {
                const student = qs.parse(localStorage.getItem('student'), {
                  decoder(str, decoder, charset) {
                    const strWithoutPlus = str.replace(/\+/g, ' ');
                    if (charset === 'iso-8859-1') {
                      // unescape never throws, no try...catch needed:
                      return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
                    }

                    if (/^(\d+|\d*\.\d+)$/.test(str)) {
                      return parseFloat(str);
                    }

                    const keywords = {
                      true: true,
                      false: false,
                      null: null,
                      undefined,
                    };

                    if (str in keywords) {
                      return keywords[str];
                    }

                    // utf-8
                    try {
                      return decodeURIComponent(strWithoutPlus);
                    } catch (e) {
                      return strWithoutPlus;
                    }
                  },
                });
                store.dispatch('setCurrentStudent', student);
              }

              // Get stored tc
              if (localStorage.getItem('tc') && !store.getters.tc) {
                const tc = qs.parse(localStorage.getItem('tc'), {
                  decoder(str, decoder, charset) {
                    const strWithoutPlus = str.replace(/\+/g, ' ');
                    if (charset === 'iso-8859-1') {
                      // unescape never throws, no try...catch needed:
                      return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
                    }

                    if (/^(\d+|\d*\.\d+)$/.test(str)) {
                      return parseFloat(str);
                    }

                    const keywords = {
                      true: true,
                      false: false,
                      null: null,
                      undefined,
                    };

                    if (str in keywords) {
                      return keywords[str];
                    }

                    // utf-8
                    try {
                      return decodeURIComponent(strWithoutPlus);
                    } catch (e) {
                      return strWithoutPlus;
                    }
                  },
                });
                store.dispatch('setCurrentTC', tc);
              }

              // Check if redirect is present
              let redirectUrl;
              if (to.query.redirect) {
                redirectUrl = to.query.redirect;
              }

              // Get ongoing lesson if exists
              if (localStorage.getItem(`lesson_${store.getters.me.username}`)) {
                const lesson = JSON.parse(
                  localStorage.getItem(`lesson_${store.getters.me.username}`),
                );
                if (lesson.start && lesson.end) {
                  lesson.start = new Date(lesson.start.replace(/ /g, 'T'));
                  lesson.end = new Date(lesson.end.replace(/ /g, 'T'));
                  store.dispatch('setLesson', lesson);
                  if (redirectUrl) {
                    router.push({ path: redirectUrl });
                  } else {
                    router.push({ path: '/lesson/on' });
                  }
                } else {
                  // there is inconsistency in the lesson, we drop it
                  store.dispatch('unsetLesson');
                }
              }

              // get stored calendar view mode
              const view = localStorage.getItem('view');
              store.dispatch('setView', view);

              // get stored wizard mode
              const wizard = JSON.parse(localStorage.getItem('wizard'));
              store.dispatch('setWizard', wizard);
              if (redirectUrl) {
                router.push({ path: redirectUrl });
              } else {
                next();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (router.currentRoute.name !== 'login') {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    }
  } else if (to.name === 'signup' && from.name === 'login') {
    // Check if redirect is present
    let redirectUrl;
    if (from.query.redirect) {
      redirectUrl = from.query.redirect;
    }

    if (redirectUrl && !to.query.redirect) {
      router.replace({
        path: to.fullPath,
        query: { redirect: redirectUrl },
      });
    }
    next();
  } else {
    next();
  }
});

Vue.config.productionTip = false;
new Vue({
  store,
  router,
  i18n,
  components: {
    Scheduler,
    SchedulerResource,
    SchedulerView,
    Button,
    ButtonGroup,
    ButtonGroupButton,
    Window,
    Validator,
    MaskedTextBox,
    ColorPicker,
    KendoTooltip,
    ComboBox,
    MultiSelect,
    DataSource,
    SchedulerDataSource,
    DatePicker,
    DateTimePicker,
    Chart,
    ChartSeriesItem,
    MediaPlayer,
    ListBox,
    Cropper,
    DropDownList,
  },
  render: (h) => h(App),
}).$mount('#app');
