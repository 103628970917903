import store from '../../store';

const VueAcl = {
  install(Vue, options) {
    const getParent = function getParent(child, roles) {
      let parents = [];
      const parent = roles.find((obj) => obj.name === child.parent);

      if (parent) {
        parents = getParent(parent, roles);
        parents.push(parent);
      }

      return parents.length !== 0 ? parents : [];
    };

    const verifyPrivileges = function verifyPrivileges(loRole, loResource, loPrivileges) {
      let isAllowed = false;
      // Get role
      let roleObj = options.acl.roles.find(
        (obj) =>
          obj.name.toLowerCase() === loRole || obj.aliases.some((a) => a.toLowerCase() === loRole),
      );

      if (typeof roleObj === 'undefined' && loRole === '') {
        roleObj = {};
      } else if (typeof roleObj === 'undefined') {
        roleObj = options.acl.roles.find((obj) => obj.name.toLowerCase() === 'user');
      }
      // Check if resource exists
      if (
        Object.keys(options.acl.resources).some((k) => options.acl.resources[k].name === loResource)
      ) {
        // Check if role exists
        if (
          Object.keys(options.acl.roles).some((k) => options.acl.roles[k].name === roleObj.name)
        ) {
          // Get parents roles
          const userRoleObj = options.acl.roles.find((obj) => obj.name === roleObj.name);

          const parents = getParent(userRoleObj, options.acl.roles);

          // Check if role is allowed to access the resource
          isAllowed = options.acl.rules.some((rule) => {
            const isParent = parents.some((parent) => rule.role.indexOf(parent.name) !== -1);
            const isAllPrivilegesArray = [];
            if (
              (rule.resource.indexOf(loResource) !== -1 || rule.resource.indexOf('app') !== -1) &&
              (rule.role.indexOf(roleObj.name) !== -1 || isParent)
            ) {
              // Check each privilege
              loPrivileges.forEach((privilege) => {
                if (
                  rule.privileges.indexOf(privilege) !== -1 ||
                  rule.privileges.indexOf('all') !== -1
                ) {
                  isAllPrivilegesArray.push(true);
                } else {
                  isAllPrivilegesArray.push(false);
                }
              });
            } else {
              isAllPrivilegesArray.push(false);
            }

            return isAllPrivilegesArray.indexOf(false) === -1;
          });
        }
      }
      return isAllowed;
    };

    Vue.directive('can', {
      bind(el, binding, vnode) {
        const userRoles = store.getters.me ? store.getters.me.roles : [];
        const resource = binding.arg;
        const privileges = Object.keys(binding.modifiers);
        const isAllowed = userRoles.some((userRole) =>
          verifyPrivileges(userRole.name.toLowerCase(), resource, privileges),
        );
        if (!isAllowed) {
          const comment = document.createComment(' ');
          Object.defineProperty(comment, 'setAttribute', {
            value: () => undefined,
          });

          vnode.elm = comment;
          vnode.text = ' ';
          vnode.isComment = true;
          vnode.context = undefined;
          vnode.tag = undefined;
          vnode.data.directives = undefined;

          if (vnode.componentInstance) {
            vnode.componentInstance.$el = comment;
          }

          if (el.parentNode) {
            el.parentNode.replaceChild(comment, el);
          }
        }
      },
    });

    Vue.prototype.$can = function can(loResource, loPrivileges) {
      const privileges = Array.isArray(loPrivileges) ? loPrivileges : [loPrivileges];
      let userRoles = store.getters.me ? store.getters.me.roles : [];
      if (!userRoles) {
        userRoles = [];
      }
      return userRoles.some((userRole) =>
        verifyPrivileges(userRole.name.toLowerCase(), loResource, privileges),
      );
    };

    Vue.prototype.$hasRole = function hasRole(loRole) {
      const userRoles = store.getters.me ? store.getters.me.roles : [];
      return userRoles.some((userRole) => userRole.name.toLowerCase() === loRole.toLowerCase());
    };
    Vue.prototype.$hasOnlyRole = function hasOnlyRole(loRole) {
      const userRoles = store.getters.me ? store.getters.me.roles : [];
      return (
        userRoles.length === 1 &&
        userRoles.some((userRole) => userRole.name.toLowerCase() === loRole.toLowerCase())
      );
    };
  },
};

export default VueAcl;
