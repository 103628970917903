import axios from 'axios';
import LZString from 'lz-string';

const VueNBP = {
  install(Vue, options) {
    const bloom = {
      initialized: false,
      hashRounds: null,
      bitArray: null,
      bitArrayLength: null,
    };

    let isNBPList = false;
    const colors = options.colors ? options.colors : ['error', 'orange', 'warning', 'success'];

    bloom.hashes = {
      djb2(str) {
        let hash = 5381;
        for (let len = str.length, count = 0; count < len; count += 1) {
          hash = (hash * 33) ^ str.charCodeAt(count); // eslint-disable-line no-bitwise
        }

        return (hash >>> 0) % bloom.bitArrayLength; // eslint-disable-line no-bitwise
      },
      sdbm(str) {
        let hash = 0;
        for (let len = str.length, count = 0; count < len; count += 1) {
          // eslint-disable-next-line no-bitwise, no-mixed-operators
          hash = str.charCodeAt(count) + (hash << 6) + (hash << 16) - hash;
        }

        return (hash >>> 0) % bloom.bitArrayLength; // eslint-disable-line no-bitwise
      },
      getIndices(str) {
        const hashes = [];
        hashes.push(bloom.hashes.djb2(str));
        hashes.push(bloom.hashes.sdbm(str));

        for (let round = 2; round <= bloom.hashRounds; round += 1) {
          // eslint-disable-next-line no-bitwise
          const roundPerHashes = round * hashes[1];
          const newHash = (hashes[0] + roundPerHashes + (round ^ 2)) % bloom.bitArrayLength;
          hashes.push(newHash);
        }

        return hashes;
      },
    };

    bloom.init = function init(contents, listLength) {
      const rawData = LZString.decompressFromUTF16(contents);
      const data = rawData.split(',');
      bloom.bitArrayLength = data.length * 128;
      bloom.hashRounds = Math.round((Math.log(2.0) * bloom.bitArrayLength) / listLength);
      bloom.bitArray = new Uint8Array(data);
      bloom.initialized = true;
    };

    bloom.checkEntry = function checkEntry(str) {
      if (!bloom.initialized) {
        throw new Error('Bloom filter has not been initialized. Cannot run');
      }

      const indices = bloom.hashes.getIndices(str);
      for (let i = indices.length - 1; i >= 0; i -= 1) {
        const extraIndices = indices[i] % 8;
        const index = (indices[i] - extraIndices) / 8;
        // eslint-disable-next-line no-bitwise
        if (extraIndices !== 0 && (bloom.bitArray[index] & (128 >> (extraIndices - 1))) === 0) {
          return false;
          // eslint-disable-next-line no-bitwise
        }
        if (extraIndices === 0 && (bloom.bitArray[index] & 1) === 0) {
          return false;
        }
      }

      return true;
    };

    const loadCommonPasswordFile = function loadCommonPasswordFile() {
      if (!isNBPList) {
        const fileSplit = options.file.split('_');
        if (localStorage.getItem(`IBP_${fileSplit[fileSplit.length - 1]}`)) {
          const bloomContent = localStorage.getItem(`IBP_${fileSplit[fileSplit.length - 1]}`);
          bloom.init(bloomContent, fileSplit[fileSplit.length - 1]);
          isNBPList = true;
        } else {
          axios({
            method: 'get',
            url: `${options.api}${options.file}`,
          })
            .then((response) => {
              const bloomContent = response.data.data.content;
              // cache content
              localStorage.setItem(`IBP_${fileSplit[fileSplit.length - 1]}`, bloomContent);
              bloom.init(bloomContent, fileSplit[fileSplit.length - 1]);
              isNBPList = true;
            })
            .catch(() => {
              isNBPList = false;
            });
        }
      }
    };
    const isCommonPassword = function isCommonPassword(password) {
      return bloom.checkEntry(password) || bloom.checkEntry(password.toLowerCase());
    };

    const getComplexity = function getComplexity(password) {
      let score = 0;
      let variation = 0;
      let uppercase = 0;
      let lowercase = 0;
      let digits = 0;
      let symbols = 0;
      const pLength = password.length;
      const p = password;
      const minPLength = options.minLength ? options.minLength : 12;
      const letters = {};

      if (pLength > 0) {
        for (let i = 0; i < pLength; i += 1) {
          if (p[i].match(/[A-Z]/g)) uppercase += 1;
          if (p[i].match(/[a-z]/g)) lowercase += 1;
          if (p[i].match(/[0-9]/g)) digits += 1;
          if (p[i].match(/[^A-Za-z0-9]/g)) symbols += 1;
          letters[p[i]] = (letters[p[i]] || 0) + 1;
          score += 5.0 / letters[p[i]];
        }

        if (uppercase > 0) variation += 1;
        if (lowercase > 0) variation += 1;
        if (digits > 0) variation += 1;
        if (symbols > 0) variation += 1;

        score += (variation - 1) * 10;

        if (pLength < minPLength) score -= pLength * 1.5;

        if (variation < 3) {
          if (uppercase > 0) score -= uppercase;
          if (lowercase > 0) score -= lowercase;
          if (digits > 0) score -= digits;
          if (symbols > 0) score -= symbols;

          score = Math.min(59, score);
        }

        if (isNBPList && isCommonPassword(p)) {
          score = 1;
        }

        if (score > 100) score = 100;
        if (score < 0) score = 0;
      }

      return score;
    };

    const getColor = function getColor(complexity) {
      return colors[Math.floor(complexity / 30)];
    };

    const getHeight = function getHeight(password) {
      if (password.length === 0) {
        return 2;
      }

      return 2;
    };

    const getHint = function getHint(password, complexity) {
      if (password.length === 0) {
        return 'LNG_PASSWORD_EMPTY';
      }

      if (complexity < 30) {
        return 'LNG_PASSWORD_HUGLY';
      }
      if (complexity < 60) {
        return 'LNG_PASSWORD_BAD';
      }
      if (complexity < 80) {
        return 'LNG_PASSWORD_SUFFICIENT';
      }

      return 'LNG_PASSWORD_GOOD';
    };

    Vue.prototype.$initCommonPasswordFile = function initCommonPasswordFile() {
      loadCommonPasswordFile();
    };
    Vue.prototype.$getPasswordComplexity = function getPasswordComplexity(password) {
      const complexity = getComplexity(password);
      const color = getColor(complexity);
      const height = getHeight(password);
      const hint = getHint(password, complexity);
      return {
        complexity,
        color,
        height,
        hint,
      };
    };
  },
};

export default VueNBP;
