const languages = [
  {
    value: 'de',
    text: 'DE - Deutsch',
    code: 'DE',
    locale: 'de-CH',
  },
  {
    value: 'fr',
    text: 'FR - Français',
    code: 'FR',
    locale: 'fr-CH',
  },
  {
    value: 'it',
    text: 'IT - Italiano',
    code: 'IT',
    locale: 'it-CH',
  },
];

export default languages;
