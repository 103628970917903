import Vue from 'vue';
import Vuex from 'vuex';
import qs from 'qs';
import axios from 'axios';
import moment from 'moment';
import i18n from './plugins/i18n';
import router from './router';
import languages from './locales/languages';

Vue.use(Vuex);

const locales = {
  de: 'de',
  'de-ch': 'de',
  'de-de': 'de',
  fr: 'fr',
  'fr-ch': 'fr',
  'fr-fr': 'fr',
  it: 'it',
  'it-ch': 'it',
  'it-it': 'it',
};

const localesCode = {
  de: 'de-CH',
  'de-ch': 'de-CH',
  'de-de': 'de-CH',
  fr: 'fr-CH',
  'fr-ch': 'fr-CH',
  'fr-fr': 'fr-CH',
  it: 'it',
  'it-ch': 'it-CH',
  'it-it': 'it-CH',
};

export default new Vuex.Store({
  state: {
    lang: localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : locales[navigator.language.toLowerCase()],
    locale: localStorage.getItem('locale')
      ? localStorage.getItem('lang')
      : localesCode[navigator.language.toLowerCase()],
    me: undefined,
    ua: navigator.userAgent,
    os: navigator.platform,
    defaultDuration: 60, // in minutes
    onGoingLesson: {},
    jobs: [],
    chips: [],
    initLesson: undefined,
    student: undefined,
    tc: undefined,
    online: navigator.onLine,
    view: undefined,
    calendar: {},
    referer: '/',
    currentLessonId: '',
    appVersion: process.env.VUE_APP_VERSION || '0.000.0',
    releases: [],
    wizard: false,
    isLoggedIn: false,
  },
  getters: {
    me: (state) => state.me,
    isMobile: (state) =>
      (state.ua.match(/Android/i) ||
        state.ua.match(/iPhone/i) ||
        state.ua.match(/iPad/i) ||
        state.ua.match(/webOS/i)) !== null ||
      (state.os.match(/MacIntel/i) !== null && navigator.maxTouchPoints > 1),
    isIPad: (state) => state.ua.match(/Macintosh/i) !== null && navigator.maxTouchPoints > 1,
    os: (state) => state.os,
    ua: (state) => state.ua,
    defaultDuration: (state) => state.defaultDuration,
    lesson: (state) => state.onGoingLesson,
    isOnGoingLesson: (state) => !!state.onGoingLesson.appointment,
    hasDidacticLicense: (state) => (state.me ? state.me.licenceDidactique : false),
    hasFinancialLicense: (state) => (state.me ? state.me.licenceFinance : false),
    hasStatisticsLicense: (state) => (state.me ? state.me.licenceStatistiques : false),
    hasAdminLicense: (state) => (state.me ? state.me.licenceAdmin : false),
    language: (state) => (state.lang ? state.lang : 'fr'),
    locale: (state) => (state.locale ? state.locale : 'fr-CH'),
    jobs: (state) => state.jobs,
    isNoObjectives: (state) => (state.me ? state.me.trainingCardLevel === 1 : false),
    chips: (state) => state.chips,
    initLesson: (state) => state.initLesson,
    vxStudent: (state) => state.student,
    tc: (state) => state.tc,
    online: (state) => state.online,
    view: (state) => state.view,
    vxCalendar: (state) => state.calendar,
    referer: (state) => state.referer,
    currentLesson: (state) => state.currentLessonId,
    version: (state) => state.appVersion,
    isReleases: (state) => state.releases.length > 0,
    releases: (state) => state.releases,
    isWizard: (state) => state.wizard,
    isLoggedIn: (state) => state.isLoggedIn,
  },
  mutations: {
    SET_LANGUAGE(state, lang) {
      if (state.me) {
        axios({
          method: 'PUT',
          url: '/auth/changelang',
          data: { language: lang },
        })
          .then(() => {})
          .catch(() => {});
      }
      const localState = state;
      const language = languages.find((l) => l.value === locales[lang]);
      i18n.locale = language.value;
      moment.locale(language.locale);
      import(`@progress/kendo-ui/js/messages/kendo.messages.${language.locale}`);
      // eslint-disable-next-line no-undef
      kendo.culture(language.locale);

      localState.lang = language.value;
      localState.locale = language.locale;
      localStorage.setItem('lang', language.value);
      localStorage.setItem('locale', language.locale);
    },
    AUTHENTICATE(state, obj) {
      const localState = state;
      localState.me = obj.user;
      localStorage.setItem('user', qs.stringify(obj.user, { arrayFormat: 'indices' }));
      localState.isLoggedIn = true;
      sessionStorage.setItem('isLoggedIn', JSON.stringify(true));
    },
    DEAUTHENTICATE(state) {
      state.me = undefined;
      state.onGoingLesson = { ...{} };
      state.jobs = [];
      sessionStorage.removeItem('jwt');
      localStorage.removeItem('wizard');
      localStorage.removeItem('user');
      localStorage.removeItem('ali');
      localStorage.removeItem('alt');
      localStorage.removeItem('jobs');
      localStorage.removeItem('releases');
      localStorage.removeItem('view');
      localStorage.removeItem('chips');
      if (router.currentRoute.name !== 'login') router.push({ path: '/login' });
    },
    UPDATE_USERNAME(state, username) {
      const localState = state;
      localState.me.username = username;
    },
    UPDATE_ME(state, me) {
      state.me = Object.assign({}, state.me, me); // eslint-disable-line
      localStorage.setItem('user', qs.stringify(state.me, { arrayFormat: 'indices' }));
    },
    SET_LESSON(state, lesson) {
      state.onGoingLesson = lesson; // Object.assign({}, state.onGoingLesson, lesson);
      // localStorage.setItem(`lesson_${state.me.username}`, qs.stringify(lesson, { arrayFormat: 'indices' }));
      localStorage.setItem(`lesson_${state.me.username}`, JSON.stringify(lesson));
    },
    UPDATE_LESSON(state, lesson) {
      state.onGoingLesson = { ...state.onGoingLesson, ...lesson }; // Object.assign({}, state.onGoingLesson, lesson);
      // localStorage.setItem(`lesson_${state.me.username}`, qs.stringify(lesson, { arrayFormat: 'indices' }));
      localStorage.setItem(`lesson_${state.me.username}`, JSON.stringify(state.onGoingLesson));
    },
    UNSET_LESSON(state) {
      const localState = state;
      localState.onGoingLesson = {};
      localStorage.removeItem(`lesson_${state.me.username}`);
    },
    SET_JOBS(state, jobs) {
      state.jobs = jobs;
      localStorage.setItem('jobs', qs.stringify(jobs, { arrayFormat: 'brackets' }));
    },
    UNSET_JOBS(state) {
      const localState = state;
      localState.jobs = [];
      localStorage.removeItem('jobs');
    },
    UPDATE_LOGO(state, logo) {
      const localState = state;
      localState.me.logo = logo;
    },
    SET_CHIPS(state, chips) {
      state.chips = chips;
      localStorage.setItem('chips', qs.stringify(chips, { arrayFormat: 'brackets' }));
    },
    UNSET_CHIPS(state) {
      const localState = state;
      localState.chips = [];
      localStorage.removeItem('chips');
    },
    SET_INIT_LESSON(state, initLesson) {
      state.initLesson = initLesson;
    },
    UNSET_INIT_LESSON(state) {
      const localState = state;
      localState.initLesson = undefined;
    },
    SET_CURRENT_STUDENT(state, student) {
      state.student = student;
      localStorage.setItem('student', qs.stringify(student, { arrayFormat: 'indices' }));
    },
    UNSET_CURRENT_STUDENT(state) {
      const localState = state;
      localState.student = undefined;
      localStorage.removeItem('student');
    },
    SET_CURRENT_TC(state, tc) {
      state.tc = tc;
      localStorage.setItem('tc', qs.stringify(tc, { arrayFormat: 'indices' }));
    },
    UNSET_CURRENT_TC(state) {
      const localState = state;
      localState.tc = undefined;
      localStorage.removeItem('tc');
    },
    TEST_CONNECTION(state) {
      const localState = state;
      localState.online = navigator.onLine;
    },
    SET_VIEW(state, view) {
      state.view = view;
      localStorage.setItem('view', view);
    },
    UPDATE_CALENDAR(state, calendar) {
      state.calendar = { ...state.calendar, ...calendar };
    },
    SET_REFERER(state, referer) {
      state.referer = referer;
    },
    SET_CURRENT_LESSON(state, lessonId) {
      state.currentLessonId = lessonId;
    },
    SET_RELEASES(state, releases) {
      state.releases = releases;
      localStorage.setItem('releases', qs.stringify(releases, { arrayFormat: 'brackets' }));
    },
    SET_WIZARD(state, wizard) {
      state.wizard = wizard;
      localStorage.setItem('wizard', JSON.stringify(wizard));
    },
    SET_IS_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
      localStorage.setItem('isLoggedIn', JSON.stringify(value));
    },
  },
  actions: {
    changeLanguage({ commit }, payload) {
      commit('SET_LANGUAGE', payload);
    },
    authenticate({ commit }, value) {
      commit('AUTHENTICATE', value);
    },
    deauthenticate({ commit }) {
      commit('DEAUTHENTICATE');
    },
    updateUsername({ commit }, value) {
      commit('UPDATE_USERNAME', value);
    },
    updateMe({ commit }, value) {
      commit('UPDATE_ME', value);
    },
    setLesson({ commit }, value) {
      commit('SET_LESSON', value);
    },
    updateLesson({ commit }, value) {
      commit('UPDATE_LESSON', value);
    },
    unsetLesson({ commit }, value) {
      commit('UNSET_LESSON', value);
    },
    setJobs({ commit }, value) {
      commit('SET_JOBS', value);
    },
    unsetJobs({ commit }, value) {
      commit('UNSET_JOBS', value);
    },
    updateLogo({ commit }, value) {
      commit('UPDATE_LOGO', value);
    },
    setChips({ commit }, value) {
      commit('SET_CHIPS', value);
    },
    unsetChips({ commit }, value) {
      commit('UNSET_CHIPS', value);
    },
    setInitLesson({ commit }, value) {
      commit('SET_INIT_LESSON', value);
    },
    unsetInitLesson({ commit }, value) {
      commit('UNSET_INIT_LESSON', value);
    },
    setCurrentStudent({ commit }, value) {
      commit('SET_CURRENT_STUDENT', value);
    },
    unsetCurrentStudent({ commit }, value) {
      commit('UNSET_CURRENT_STUDENT', value);
    },
    setCurrentTC({ commit }, value) {
      commit('SET_CURRENT_TC', value);
    },
    unsetCurrentTC({ commit }, value) {
      commit('UNSET_CURRENT_TC', value);
    },
    testConnection({ commit }) {
      commit('TEST_CONNECTION');
    },
    setView({ commit }, value) {
      commit('SET_VIEW', value);
    },
    updateCalendar({ commit }, value) {
      commit('UPDATE_CALENDAR', value);
    },
    setReferer({ commit }, value) {
      commit('SET_REFERER', value);
    },
    setCurrentLesson({ commit }, value) {
      commit('SET_CURRENT_LESSON', value);
    },
    setReleases({ commit }, value) {
      commit('SET_RELEASES', value);
    },
    setWizard({ commit }, value) {
      commit('SET_WIZARD', value);
    },
    setIsLoggedIn({ commit }, value) {
      commit('SET_IS_LOGGED_IN', value);
    },
  },
});
